import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import vars from './vars.module.scss';

const recOneTheme = {
  palette: {
    primary: {
      main: vars.primary
    },
    secondary: {
      main: vars.secondary
    },
    success: {
      main: vars.success
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        'html, body': {
          background: vars.primaryBackground
        },
        a: {
          color: vars.primary
        },
        '.AuthContainer': {
          minHeight: '90vh',
          alignContent: 'center',
          justifyContent: 'center',
          margin: '25px 0'
        },
        '.ButtonsContainer': {
          marginTop: '50px'
        },
        '.MuiFormControl-root.MuiTextField-root': {
          marginBottom: '16px'
        }
      }
    }
  },
  typography: {
    fontFamily: ['Roboto', 'sans-serif'].join(','),
    title: {
      fontSize: 22,
      fontWeight: 400,
      lineHeight: 28
    },
    subtitle: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: 24,
      letterSpacing: 0.1
    },
    text: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: 20,
      letterSpacing: 0.1
    },
    labelText: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: 16,
      letterSpacing: 0.5
    }
  }
};

type CustomTheme = {
  [Key in keyof typeof recOneTheme]: typeof recOneTheme[Key];
};

declare module '@mui/material/styles/createTheme' {
  interface Theme extends CustomTheme { }

  interface ThemeOptions extends CustomTheme { }
}

let theme = createTheme(recOneTheme);
theme = responsiveFontSizes(theme);

export default createTheme(recOneTheme);
