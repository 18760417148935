import { CategoryDataType, cbCategories, cbCategoryGroups } from '@recone/models';
import {
  industries,
  numberOfPresent,
  timeframes,
  companySizes,
  revenueRanges,
  fundingTypeOptions
} from '@recone/models';

export const initialFilters: CategoryDataType[] = [
  {
    name: 'Profile',
    conjuction: 'AND',
    filters: [
      {
        name: 'First Name',
        path: 'profile.firstName',
        filterType: 'multiSelectCreatable',
        criteria: []
      },
      {
        name: 'Last Name',
        path: 'profile.lastName',
        filterType: 'multiSelectCreatable',
        criteria: []
      },
      {
        name: 'Location',
        path: 'profile.location',
        filterType: 'multiSelectCreatable',
        criteria: []
      },
      {
        name: 'Skills',
        path: 'profile.skills',
        filterType: 'multiSelectCreatable',
        conjunctionOverridable: true,
        overrideConjunction: false,
        criteria: []
      },
      {
        name: 'Headline keywords',
        path: 'profile.headline',
        filterType: 'multiSelectCreatable',
        conjunctionOverridable: true,
        overrideConjunction: false,
        criteria: []
      },
      {
        name: 'About keywords',
        path: 'profile.summary',
        filterType: 'multiSelectCreatable',
        conjunctionOverridable: true,
        overrideConjunction: false,
        criteria: []
      }
    ]
  },

  {
    name: 'Experience',
    conjuction: 'AND',
    filters: [
      {
        name: 'Present Company',
        path: 'profile.experience',
        startPath: 'start',
        endPath: 'end',
        valuePath: 'companyName',
        filterType: 'multiSelectCreatable',
        timed: true,
        present: true,
        criteria: [],
        conjunctionOverridable: true,
        overrideConjunction: false
      },
      {
        name: 'Past Company',
        path: 'profile.experience',
        startPath: 'start',
        endPath: 'end',
        valuePath: 'companyName',
        filterType: 'multiSelectCreatable',
        timed: true,
        present: false,
        criteria: [],
        conjunctionOverridable: true,
        overrideConjunction: false
      },
      {
        name: 'Present Title',
        path: 'profile.experience',
        startPath: 'start',
        endPath: 'end',
        valuePath: 'title',
        filterType: 'multiSelectCreatable',
        timed: true,
        present: true,
        criteria: []
      },
      {
        name: 'Past Title',
        path: 'profile.experience',
        startPath: 'start',
        endPath: 'end',
        valuePath: 'title',
        filterType: 'multiSelectCreatable',
        timed: true,
        present: false,
        criteria: [],
        conjunctionOverridable: true,
        overrideConjunction: false
      },
      {
        name: 'Present Company Size (Present)',
        path: 'companySize',
        filterType: 'multiSelectFromOptions',
        options: [...companySizes],
        criteria: []
      },
      {
        name: 'Present Company Size (Past)',
        path: 'companySize',
        past: true,
        filterType: 'multiSelectFromOptions',
        options: [...companySizes],
        criteria: []
      },
      {
        name: 'Years in current position',
        path: 'profile.experience',
        startPath: 'start',
        endPath: 'end',
        isTimeframe: true,
        present: true,
        filterType: 'multiSelectFromOptions',
        criteria: [],
        options: [...timeframes]
      },
      {
        name: 'Years of experience',
        path: 'totalExperience',
        filterType: 'multiSelectFromOptions',
        criteria: [],
        options: [...timeframes]
      },
      {
        name: 'Number of present companies',
        path: 'numberOfPresentCompanies',
        filterType: 'multiSelectFromOptions',
        criteria: [],
        options: [...numberOfPresent]
      },
      {
        name: 'Experience keywords (Present)',
        path: 'company.description',
        filterType: 'multiSelectCreatable',
        conjunctionOverridable: true,
        overrideConjunction: false,
        criteria: []
      },
      {
        name: 'Experience keywords (Past)',
        path: 'company.description',
        past: true,
        filterType: 'multiSelectCreatable',
        conjunctionOverridable: true,
        overrideConjunction: false,
        criteria: []
      }
    ]
  },
  {
    name: 'Company',
    conjuction: 'AND',
    filters: [
      {
        name: 'Is Company Active',
        path: 'company.isActive',
        filterType: 'toggleSwitch',
        criteria: []
      },
      {
        name: 'Location',
        path: 'company.location',
        filterType: 'multiSelectCreatable',
        criteria: []
      },
      // TODO: @Mita - Commented out until we have more data.
      // {
      //   name: 'Industry',
      //   path: 'company.industries',
      //   filterType: 'multiSelectFromOptions',
      //   conjunctionOverridable: true,
      //   overrideConjunction: false,
      //   options: [...industries],
      //   criteria: []
      // },
      {
        name: 'Category (CB) (Present)',
        path: 'categories',
        filterType: 'multiSelectFromOptions',
        conjunctionOverridable: true,
        overrideConjunction: true,
        options: [...cbCategories],
        criteria: []
      },
      {
        name: 'Category (CB) (Past)',
        path: 'categories',
        past: true,
        filterType: 'multiSelectFromOptions',
        conjunctionOverridable: true,
        overrideConjunction: true,
        options: [...cbCategories],
        criteria: []
      },
      {
        name: 'Category Groups (CB) (Present)',
        path: 'categoryGroups',
        filterType: 'multiSelectFromOptions',
        conjunctionOverridable: true,
        overrideConjunction: true,
        options: [...cbCategoryGroups],
        criteria: []
      },
      {
        name: 'Category Groups (CB) (Past)',
        path: 'categoryGroups',
        past: true,
        filterType: 'multiSelectFromOptions',
        conjunctionOverridable: true,
        overrideConjunction: true,
        options: [...cbCategoryGroups],
        criteria: []
      },
      {
        name: 'Founded Year (Present)',
        path: 'company.foundedYear',
        filterType: 'datePicker',
        conjunctionOverridable: false,
        overrideConjunction: false,
        criteria: []
      },
      {
        name: 'Founded Year (Past)',
        path: 'company.foundedYear',
        past: true,
        filterType: 'datePicker',
        conjunctionOverridable: false,
        overrideConjunction: false,
        criteria: []
      }
    ]
  },
  {
    name: 'Education',
    conjuction: 'AND',
    filters: [
      {
        name: 'School Name',
        path: 'profile.education.schoolName',
        filterType: 'multiSelectCreatable',
        criteria: []
      },
      {
        name: 'Diploma type',
        path: 'profile.education.degreeName',
        filterType: 'multiSelectCreatable',
        criteria: []
      },
      {
        name: 'Diploma Major',
        path: 'profile.education.fieldOfStudy',
        filterType: 'multiSelectCreatable',
        criteria: []
      },
      {
        name: 'Start Year',
        path: 'profile.education.start',
        filterType: 'datePicker',
        conjunctionOverridable: false,
        overrideConjunction: false,
        criteria: []
      },
      {
        name: 'End Year',
        path: 'profile.education.end',
        filterType: 'datePicker',
        conjunctionOverridable: false,
        overrideConjunction: false,
        criteria: []
      },
      {
        name: 'Education description keyword',
        path: 'profile.education.description',
        filterType: 'multiSelectCreatable',
        criteria: [],
        conjunctionOverridable: true,
        overrideConjunction: false
      }
    ]
  },
  {
    name: 'Funding',
    conjuction: 'AND',
    filters: [
      {
        name: 'Revenue Range (Present)',
        path: 'revenueRange',
        filterType: 'multiSelectFromOptions',
        options: [...revenueRanges],
        criteria: []
      },
      {
        name: 'Revenue Range (Past)',
        path: 'revenueRange',
        past: true,
        filterType: 'multiSelectFromOptions',
        options: [...revenueRanges],
        criteria: []
      },
      {
        name: 'Last Funding At (Present)',
        path: 'company.lastFundingAt',
        filterType: 'datePicker',
        conjunctionOverridable: false,
        overrideConjunction: false,
        criteria: []
      },
      {
        name: 'Last Funding At (Past)',
        path: 'company.lastFundingAt',
        past: true,
        filterType: 'datePicker',
        conjunctionOverridable: false,
        overrideConjunction: false,
        criteria: []
      },
      {
        name: 'Last Funding Type (Present)',
        path: 'company.lastFundingType',
        filterType: 'multiSelectFromOptions',
        options: [...fundingTypeOptions],
        criteria: []
      },
      {
        name: 'Last Funding Type (Past)',
        path: 'company.lastFundingType',
        past: true,
        filterType: 'multiSelectFromOptions',
        options: [...fundingTypeOptions],
        criteria: []
      }
    ]
  }
];
