import { FC, FunctionComponent, lazy, Suspense, useEffect, useRef, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import SignUp from '../pages/auth/SignUp';
import ProjectBreadcrumbs from '../components/Breadcrumbs/ProjectBreadcrumbs';
import SequenceBreadCrumbs from '../components/Breadcrumbs/SequenceBreadCrumbs';
import TeamDetailsBreadcrumbs from '../components/Breadcrumbs/TeamDetailsBreadcrumbs';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import { AuthStates, useAuth } from '../contexts/authContext';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router';
import { getInviteId } from '../hooks/api/auth';
import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';

const Login = lazy(() => import('pages/auth/Login'));
const Home = lazy(() => import('pages/home'));
const Projects = lazy(() => import('pages/Projects'));
const ProjectDetails = lazy(() => import('pages/ProjectDetails'));
const Sequences = lazy(() => import('pages/Sequences'));
const Usage = lazy(() => import('pages/Usage'));
const SequencesDetails = lazy(() => import('pages/SequenceDetails'));
const Settings = lazy(() => import('pages/Settings'));
const SenderSettings = lazy(() => import('pages/SenderSettings'));
const TeamsSettings = lazy(() => import('pages/TeamsSettings'));
const TeamsSettingsDetails = lazy(() => import('pages/TeamDetailSettings'));
const Criterias = lazy(() => import('pages/Criterias'));
const PersonalSettings = lazy(() => import('pages/PersonalSettings'));
const GoogleOAuthRedirect = lazy(() => import('../pages/GoogleOAuthRedirect'));
const MainLayout = lazy(() => import('../layouts/MainLayout'));

type routeItem = {
  path: string;
  key: string;
  exact: boolean;
  breadcrumb?: FunctionComponent<any> | string;
  component: Function;
};

type routes = routeItem & {
  routes?: routeItem[];
};

const AuthLayout: FC<JSX.IntrinsicAttributes & { routes: routes[] }> = (props) => {
  const { state, token } = useAuth();

  const { search } = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(search);
    const teamId = params.get('id');
    if (teamId && /^[\dA-Fa-f]{24}$/.test(teamId)) {
      localStorage.setItem('invitationId', teamId);
    }
  }, []);

  if (!token) {
    if (state === AuthStates.TokenExpired) {
      toast.error('Session expired. Please login again.', { toastId: 'session-expired' });
    }

    if (state === AuthStates.NotLogged && getInviteId()) {
      toast.success('You need to sign in first to accept the invitation!', { toastId: 'session-expired' });
    }

    if (state === AuthStates.NotLogged) {
      toast.success('Logged out successfully, see you soon!', { toastId: 'session-expired' });
    }

    return <Redirect to={'/'} />;
  }

  const useInvitationToTeam = (
    <>
      <Redirect to={`app/settings/teams?id=${getInviteId()}`} />
    </>
  );

  return (
    <MainLayout>
      <Suspense
        fallback={
          <Grid alignItems='center' container direction='column' justifyContent='center' sx={{ height: '100%' }}>
            <CircularProgress />
          </Grid>
        }>
        <RenderRoutes {...props} />
        {getInviteId() && useInvitationToTeam}
      </Suspense>
    </MainLayout>
  );
};

const RootRedirector: FC = () => {
  const { token } = useAuth();

  if (!token) {
    return <Redirect to={'/login'} />;
  }
  return <Redirect to={'/app'} />;
};

const ROUTES: routes[] = [
  {
    path: '/',
    key: 'ROOT',
    exact: true,
    component: RootRedirector,
    routes: []
  },
  {
    path: '/login',
    key: 'LOGIN',
    exact: true,
    component: Login,
    routes: []
  },
  {
    path: '/sign-up',
    key: 'SIGNUP',
    exact: true,
    component: SignUp,
    routes: []
  },
  {
    path: '/forgot-password',
    key: 'FORGOT_PASS',
    exact: true,
    component: ForgotPassword,
    routes: []
  },
  {
    path: '/reset-password',
    key: 'RESET_PASS',
    exact: true,
    component: ResetPassword,
    routes: []
  },
  {
    path: '/app',
    exact: false,
    key: 'APP',
    component: AuthLayout,
    routes: [
      {
        path: '/app',
        key: 'APP_ROOT',
        exact: true,
        component: Home
      },
      {
        path: '/app/projects',
        key: 'PROJECTS',
        exact: true,
        breadcrumb: 'Hubs',
        component: Projects
      },
      {
        path: '/app/projects/:projectId',
        key: 'PROJECT_DETAILS',
        exact: false,
        breadcrumb: ProjectBreadcrumbs,
        component: ProjectDetails
      },
      {
        path: '/app/sequences',
        key: 'SEQUENCES',
        breadcrumb: 'Sequences',
        exact: true,
        component: Sequences
      },
      {
        path: '/app/usage',
        key: 'USAGE',
        breadcrumb: 'Usage',
        exact: true,
        component: Usage
      },
      {
        path: '/app/sequences/:sequenceId',
        key: 'SEQUENCE_DETAILS',
        breadcrumb: SequenceBreadCrumbs,
        exact: false,
        component: SequencesDetails
      },
      {
        path: '/app/criterias',
        key: 'CRITERIAS',
        breadcrumb: 'Criterias',
        exact: true,
        component: Criterias
      },
      {
        path: '/app/settings',
        key: 'SETTINGS',
        breadcrumb: 'Settings',
        exact: true,
        component: Settings
      },
      {
        path: '/app/settings/sender',
        key: 'SENDER_SETTINGS',
        breadcrumb: 'Sender settings',
        exact: true,
        component: SenderSettings
      },
      {
        path: '/app/settings/personal',
        key: 'PERSONAL_SETTINGS',
        breadcrumb: 'Personal settings',
        exact: true,
        component: PersonalSettings
      },
      {
        path: '/app/settings/teams',
        key: 'TEAMS_SETTINGS',
        breadcrumb: 'Teams settings',
        exact: true,
        component: TeamsSettings
      },
      {
        path: '/app/settings/teams/:id',
        key: 'TEAM_SETTINGS_DETAILS',
        exact: true,
        breadcrumb: TeamDetailsBreadcrumbs,
        component: TeamsSettingsDetails
      },
      {
        path: '/app/settings/sender/redirect',
        key: 'GOOGLE_REDIRECT',
        exact: false,
        component: GoogleOAuthRedirect
      }
    ]
  }
];

export default ROUTES;

export function RenderRoutes({ routes }: { routes: routes[] }) {
  return (
    <Switch>
      {routes.map((route, i) => {
        // eslint-disable-next-line react/jsx-key
        return <RouteWithSubRoutes {...route} />;
      })}

      <Route component={() => <h1>Not Found!</h1>} />
    </Switch>
  );
}

function RouteWithSubRoutes(route: routes) {
  return (
    <Route
      key={route.key}
      exact={route.exact}
      path={route.path}
      render={(props) => <route.component {...props} routes={route.routes} />}
    />
  );
}
