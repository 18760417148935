import React from 'react';
import { AuthCardProps, DefaultProps } from './AuthCard.types';
import { StyledCard } from './AuthCard.styles';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Image from '../Image';
import logoImage from '../../assets/images/recone_logo-1.png';

const AuthCard = (props: AuthCardProps) => {
  const { children, header, showTittle, subHeader } = props;

  return (
    <StyledCard {...props} className='CardContainer'>
      {showTittle && <CardHeader
        title={
          <Grid alignItems='center' container direction='column'>
            <Image src={logoImage} />
          </Grid>
        }
      />
      }
      <CardContent>
        <Grid alignItems='center' container direction='column' flexGrow={1}>
          {header && (
            <Grid alignItems='center' item mb={3} textAlign='left' width='100%'>
              <Typography mb={1} variant='h5'>
                {header}
              </Typography>
              {header && (
                <Typography mb={1} variant='subtitle1'>
                  {subHeader}
                </Typography>
              )}
            </Grid>
          )}
          <Grid alignItems='center' item width='100%'>
            {children}
          </Grid>
        </Grid>
      </CardContent>
    </StyledCard>
  );
};

AuthCard.defaultProps = DefaultProps;

export default AuthCard;
