import { useAxios } from '../useAxios';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  forgotPassword,
  getCurrentUser,
  resetPassword,
  signIn,
  signUp,
  updatePassword,
  updateUser,
  UserSignUpType,
  verify
} from '../../services/api/auth';
import { IClient, ITeam } from '@recone/models';
import { useAuth } from 'contexts/authContext';
import { AxiosResponse } from 'axios';

export const GET_CURRENT_USER_QUERY_ID = 'client';

export function useSignIn() {
  const axios = useAxios();

  return useMutation((args: { email: string; password: string }) => signIn(axios, args.email, args.password));
}

export function useSignUp() {
  const axios = useAxios();

  return useMutation((args: { userData: UserSignUpType }) => signUp(axios, args.userData));
}

export function setTeamId(id: string) {
  localStorage.setItem('teamId', id);
}

function setDefaultTeamId(res: AxiosResponse<IClient>) {
  const teamId = localStorage.getItem('teamId');

  if (!teamId && (res.data.teams?.[0] as ITeam)?._id) {
    localStorage.setItem('teamId', (res.data.teams?.[0] as ITeam)?._id);
  }

  return res;
}

export function useVerify() {
  const axios = useAxios();

  return useMutation((args: { email: string; code: string }) =>
    verify(axios, args.email, args.code).then((res) => {
      return setDefaultTeamId(res);
    })
  );
}

export function useGetCurrentUser() {
  const axios = useAxios();
  const { token } = useAuth();

  return useQuery(
    [GET_CURRENT_USER_QUERY_ID],
    () =>
      getCurrentUser(axios).then((res) => {
        return setDefaultTeamId(res);
      }),
    {
      enabled: !!token
    }
  );
}

export function useUpdateUserProfile() {
  const axios = useAxios();
  const queryClient = useQueryClient();
  const teamId = useTeamId();

  return useMutation(
    (args: { id: string; name: string; surname: string; email: string }) =>
      updateUser(axios, args.id, args.name, args.surname, args.email, teamId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_CURRENT_USER_QUERY_ID]).then();
      }
    }
  );
}

export function useUpdatePassword() {
  const axios = useAxios();
  const queryClient = useQueryClient();
  const teamId = useTeamId();

  return useMutation(
    (args: { clientId: string; oldPassword: string; newPassword: string; confirmPassword: string }) =>
      updatePassword(axios, args.clientId, args.oldPassword, args.newPassword, args.confirmPassword, teamId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_CURRENT_USER_QUERY_ID]).then();
      }
    }
  );
}

export function useSignOut() {
  const auth = useAuth();

  return () => {
    auth.removeToken();
    localStorage.removeItem('teamId');
  };
}

export function useTeamId(): string {
  const teamId = useMaybeTeamId();

  if (!teamId) {
    throw new Error('No teamId found');
  }

  return teamId;
}

export function useMaybeTeamId(): string | null {
  return localStorage.getItem('teamId');
}

export function getInviteId() {
  return localStorage.getItem('invitationId');
}

export function useForgotPassword() {
  const axios = useAxios();

  return useMutation((args: { email: string }) => forgotPassword(axios, args.email));
}

export function useResetPassword() {
  const axios = useAxios();

  return useMutation((args: { code: string; password: string; repeatPassword: string }) =>
    resetPassword(axios, args.code, args.password, args.repeatPassword)
  );
}
