import { createContext, PropsWithChildren, useMemo } from 'react';
import axios, { AxiosInstance } from 'axios';
import { useAuth } from './authContext';

const BACKEND_RESPONSE_ACCESS_TOKEN = 'x-auth-token';
const BACKEND_REQUEST_ACCESS_TOKEN = 'x-access-token';

const AUTH_FAILED_MESSAGE = 'Authentication failed';

export const AxiosContext = createContext<AxiosInstance | null>(null);

export default function AxiosProvider({ children }: PropsWithChildren<unknown>) {
  const auth = useAuth();

  const axiosMemo = useMemo(() => {
    const axiosInstance = axios.create({
      headers: {
        'codebase-version': process.env.REACT_APP_VERSION ?? '',
        'Content-Type': 'application/json'
      }
    });

    axiosInstance.interceptors.request.use((config) => {
      // Read token for anywhere, in this case directly from localStorage
      const token = auth.getToken();
      if (token && config.headers) {
        config.headers[BACKEND_REQUEST_ACCESS_TOKEN] = `${token}`;
      }

      return config;
    });

    axiosInstance.interceptors.response.use(
      (response) => {
        if (response.status === 200 && response.headers[BACKEND_RESPONSE_ACCESS_TOKEN]) {
          auth.setToken(response.headers[BACKEND_RESPONSE_ACCESS_TOKEN]);
        }

        return response;
      },
      (error) => {
        const response = error.response;

        if (response.status === 401 && response.data?.message === AUTH_FAILED_MESSAGE && auth.token) {
          auth.removeToken(true);
          localStorage.removeItem('teamId');
        }

        return Promise.reject(error);
      }
    );

    return axiosInstance;
  }, []);

  return <AxiosContext.Provider value={axiosMemo}>{children}</AxiosContext.Provider>;
}
