import { styled } from '@mui/material/styles';
import { AuthCardProps } from './AuthCard.types';
import Card from '@mui/material/Card';

export const StyledCard = styled(Card)<AuthCardProps>(({ theme }) => {
  return {
    '&.CardContainer': {
      padding: 30,
      width: 600
    },
    '.MuiCardContent-root': {
      padding: '25px 70px!important'
    },
    '.Image img': {
      maxWidth: 265,
      marginBottom: 0
    },
    '.AuthForm': {
      textAlign: 'center'
    }
  };
});
