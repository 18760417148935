import React, { FC, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import './SignUp.scss';
import Grid from '@mui/material/Grid';
import AuthCard from '../../../components/AuthCard';
import Button from '../../../components/Button';
import { useSignIn, useSignUp, useVerify } from '../../../hooks/api/auth';
import { NavLink, Redirect } from 'react-router-dom';
import EmailVerification from '../../EmailVerification';
import TogglePasswordVisibility from '../../../components/TogglePasswordVisibility';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { TextField } from 'formik-mui';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import PasswordStrengthIndicator, {
  PasswordStrength
} from '../../../components/PasswordStrengthIndicator/PasswordStrengthIndicator';
import { checkPasswordStrength } from '../../../helpers/common';

const SignUpSchema = Yup.object().shape({
  fullName: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string().required('Required').min(8, 'Password is not valid'),
  repeatPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], "Passwords don't match!")
    .required('Required')
});

interface SignUpProps {}

const SignUp: FC<SignUpProps> = () => {
  const { search } = useLocation();
  const signUpMutation = useSignUp();
  const signInMutation = useSignIn();

  const initialPassStrengthState = {
    contains: [],
    length: 0,
    id: 0,
    value: ''
  };

  const [step, setStep] = useState(1);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const verifyCodeMutation = useVerify();
  const [passStrength, setPassStrength] = useState<PasswordStrength>(initialPassStrengthState);

  const [invitationToken, invitationEmail] = useMemo(() => {
    const params = new URLSearchParams(search);
    const id = params.get('id');
    const email = params.get('email');
    return id && /^[\dA-Fa-f]{24}$/.test(id) ? [id, email] : [undefined, undefined];
  }, [search]);

  const handleOnSubmit = (
    values: { fullName: string; email: string; password: string; repeatPassword: string },
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    signUpMutation.mutate(
      {
        userData: {
          invitationToken,
          name: values.fullName,
          surname: values.fullName,
          email: values.email,
          password: values.password,
          repeatPassword: values.repeatPassword
        }
      },
      {
        onSuccess: () => {
          setSubmitting(false);
          setEmail(values.email);
          setPassword(values.password);
          setStep(2);
        },
        onError: (error: any) => {
          setSubmitting(false);
          toast.error(error?.response?.data?.payload?.message ?? 'Error signing up...');
        }
      }
    );
  };

  // if user already "authenticated", redirect them to the app
  if (localStorage.getItem('token')) {
    return <Redirect to={'/app'} />;
  }

  const handleOnSendCode = (code: string) =>
    verifyCodeMutation.mutate(
      { email: email, code: code },
      {
        onSuccess: () => {
          toast.success(`Welcome ${email}!`);
          if (invitationToken) {
            toast.success('You just joined the team!');
          }
        },
        onError: (error: any) => {
          toast.error(error?.response?.data?.payload?.message ?? 'Wrong code, try again...');
        }
      }
    );

  const handleOnSingIn = () => signInMutation.mutate({ email, password });

  return (
    <>
      {step === 1 && (
        <Grid className="AuthContainer" container flexGrow={1} xs={12}>
          <AuthCard header='Sign up' subHeader='Register account for Recone'>
            <Formik
              initialValues={{
                fullName: '',
                email: invitationEmail ?? '',
                password: '',
                repeatPassword: ''
              }}
              validationSchema={SignUpSchema}
              validate={async (values) => {
                if (values.password !== '') {
                  const data = checkPasswordStrength(values.password);
                  if (data) {
                    return setPassStrength(data);
                  }
                  return {};
                } else {
                  return setPassStrength(initialPassStrengthState);
                }
              }}
              onSubmit={(values, { setSubmitting }) => handleOnSubmit(values, setSubmitting)}
            >
              {({ errors, isSubmitting, resetForm, submitForm, touched, values }) => (
                <Form className='AuthForm'>
                  <Field
                    component={TextField}
                    fullWidth={true}
                    id="fullname-input"
                    label="Full name"
                    margin='dense'
                    name="fullName"
                    type="text"
                  />
                  <Field
                    component={TextField}
                    fullWidth={true}
                    id="email-input"
                    label="Email"
                    margin='dense'
                    name="email"
                    type="text"
                    disabled={!!invitationEmail}
                  />
                  <Field
                    InputProps={{
                      endAdornment: (
                        <TogglePasswordVisibility
                          showPassword={showPassword}
                          onClick={() => setShowPassword(!showPassword)}
                        />
                      )
                    }}
                    component={TextField}
                    fullWidth={true}
                    id="password-input"
                    label="Password"
                    margin='dense'
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                  />
                  <PasswordStrengthIndicator password={passStrength}></PasswordStrengthIndicator>
                  <Field
                    InputProps={{
                      endAdornment: (
                        <TogglePasswordVisibility
                          showPassword={showConfirmPassword}
                          onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                        />
                      )
                    }}
                    component={TextField}
                    fullWidth={true}
                    id="confirm-password-input"
                    label="Confirm password"
                    margin='dense'
                    name="repeatPassword"
                    type={showConfirmPassword ? 'text' : 'password'}
                  />
                  {/*<Field*/}
                  {/*  component={TextField}*/}
                  {/*  fullWidth={true}*/}
                  {/*  id="team-input"*/}
                  {/*  label="Team"*/}
                  {/*  margin={'dense'}*/}
                  {/*  name="team"*/}
                  {/*/>*/}
                  <Button
                    className='AuthFormBtn'
                    color="primary"
                    disabled={isSubmitting || Object.keys(errors).length > 0 || passStrength.id < 2}
                    endIcon={isSubmitting && <CircularProgress size={21} />}
                    fullWidth={true}
                    size='large'
                    type="submit"
                  >
                    Sign up
                  </Button>
                  <Typography variant="subtitle1">
                    <NavLink to="login">Back to Sign in</NavLink>
                  </Typography>
                </Form>
              )}
            </Formik>
          </AuthCard>
        </Grid>
      )}
      {step === 2 && (
        <EmailVerification
          userEmail={email}
          userPassword={password}
          onResendCode={handleOnSingIn}
          onSendCode={handleOnSendCode}
        />
      )}
    </>
  );
};

export default SignUp;
