import React, { FC } from 'react';
import './TeamDetailsBreadcrumbs.scss';
import { useGetCurrentUser } from '../../../hooks/api/auth';
import { ITeam } from '@recone/models';

interface TeamDetailsBreadcrumbsProps {
  match?: any;
}

const TeamDetailsBreadcrumbs: FC<TeamDetailsBreadcrumbsProps> = ({ match }) => {
  const { id } = match?.params ?? '';

  const { data: currentUserData } = useGetCurrentUser();

  return <>{(currentUserData?.data.teams as ITeam[]).find((el: { _id: string }) => el._id === id)?.name}</>;
};

export default TeamDetailsBreadcrumbs;
