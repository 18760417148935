import { createContext, FC, useContext, useMemo, useState } from 'react';
import { IClient } from '@recone/models';
import { useGetCurrentUser } from '../hooks/api/auth';

export enum AuthStates {
  NotLogged,
  Logged,
  TokenExpired
}

interface AuthContextType {
  token?: any;
  getToken: () => string | null;
  setToken: (token: string) => void;
  removeToken: (sessionExpired?: boolean) => void;
  state: AuthStates;
}

export const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export const AuthProvider: FC = ({ children }) => {
  // TODO: Improve after MVP: https://www.rdegges.com/2018/please-stop-using-local-storage/
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [authState, setAuthState] = useState<AuthStates>(token ? AuthStates.Logged : AuthStates.NotLogged);

  const memoizedValue = useMemo(
    () => ({
      setToken: (token: string) => {
        setAuthState(AuthStates.Logged);
        setToken(token);
        localStorage.setItem('token', token);
      },
      removeToken: (sessionExpired = false) => {
        if (sessionExpired) {
          setAuthState(AuthStates.TokenExpired);
        } else {
          setAuthState(AuthStates.NotLogged);
        }

        if (token) {
          setToken(null);
        }

        if (localStorage.getItem('token')) {
          localStorage.removeItem('token');
        }
      },
      getToken: () => localStorage.getItem('token'),
      token: token,
      state: authState
    }),
    [token]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const authContext = useContext(AuthContext);

  if (!authContext) {
    throw 'No Auth context set yet!';
  }

  return authContext;
};
